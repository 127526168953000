@import './variable';

@include responsive("button-primary") {
    border: 1px solid $primary-color;
    background: $primary-color;
    @include font-gen(white, $secondary-font-size);
}

@include responsive("button-secondary") {
    border: 1px solid $primary-color;
    background: white;
    @include font-gen($primary-color, $secondary-font-size);

}
.button-primary,.button-secondary {
  cursor: pointer;
  border-radius: 5px !important;
  outline:none !important;
  padding:5px .5rem !important;

  img {
    width:1rem;
  }
  .button-prefix {
    @include margin-gen($right:.5rem);
  }
  .button-suffix {
    @include margin-gen($left:.5rem);
  }
}

@include responsive("hidden") {
    display: none !important;
}

.hp-segment {
    border-radius: 5px;
    border:1px solid $primary-color;
    display: inline-flex;
    overflow: hidden;

    .hp-segment-item {
        cursor: pointer;
      padding:5px .6rem;
      display: inline-flex;
    align-items: center;
    background-color: white;
      img {
        width: 1rem;
      }
      &:not(:first-child) {
        @include border-gen($left:1px solid $primary-color);
      }
      &.selected {
        background: $primary-color;
      }
    }
  }