@import "env-variable.scss";
// $theme-name: 'MOHEGY';
:root{
  --customer-color: #005A9B;
  --customer-color-light: #E0F2FF;
}
$theme-name: $env-theme;
$available-app-themes:('ROYALHAYAT', 'MOHEGY', 'ALNAHDI', 'IQVIA', 'HUMBER', 'ARTEMIS', 'MEDANTA');
$available-calendar-themes:('DEFAULT', 'ROYALHAYAT');
/* ALNAHDI*/
$primary-color: #005A9B;
$secondary-color:white;
$footer-bg-color: #00bfc7;
$header-bg-color: #A2A1A1;
$black-font-color: #212121;
$secondary-footer-color: #E0F2FF;
$primary-color-light: #E0F2FF;
$calendar-theme : 'DEFAULT';
$title-color:#2356A5;

$ternary-font-color: #757575;
$light-font-color: #989898;
$label-highlight-color: #666666;
$app-bg-color: #F8F8F8;
$border-shadow-color: #CCCCCC;
$color-danger: #EF5B5B;
$color-danger-light: #F9CCCC;
$color-success: #33B181;
$color-success-light: #E3FBDD;
$color-warning: #E69D33;
$color-warning-light: #F8F1E6;
$color-info: #17a2b8;
$color-info-light: #E3F2FD;
$color-notify: #005A9B;
$light-color-notify: lighten($color-notify, 45%);
$color-blue: #3c8ecc;
$color-pink: #e97db4;

/*calendar appointment color*/
$upcoming-apt-color: #B55CEF;
$upcoming-apt-color-light: #F7EEFD;
$past-apt-color: #5CB3EF;
$past-apt-color-light: #EEF7FD;
$upcoming-task-color: #7E5CEF;
$upcoming-task-color-light: #F2EEFD;
$past-task-color: #EF815B;
$past-task-color-light: #FDF2EE;
$light-bg: #F8F8F8;
$light-font-color: #474747;
/*Font Size*/
$primary-font-size:16px;
$secondary-font-size:13px;
$ternary-font-size:11px;
$font-size-h1: 18px;
$font-size-h2: 16px;
$font-size-p: 13px;
$font-size-label: 12px;
$font-size-cap: 10px;
$border-color: #E7E7E7;
$textarea-bg: #f9f9f9;
$text-color: #1565C0;
$box-shadow-right-light: 0 5px 10px rgba(154, 160, 185, .05),
0 15px 40px rgba(166, 173, 201, .2);
$box-shadow-bottom-dark: 0px 3px 8px $border-shadow-color;
$box-shadow-bottom-light: 0px 8px 8px #EEEEEE;
$bg-white: white;
$line-separator: white;


//Added font temporarily - for pp revamp
$header-font: 16px;
$title-font: 13px;
$subtitle-font: 11px;
$bg-green: #0DB910;
$bg-dark: #0F898F;
//
:root{
  --app-content-margin: 7rem;
  --web-footer-height: 4rem;
  --web-header-height: 4rem;
  --search-bar-width: 45%;
  --search-bar-height: 2em;
}


@if $theme-name=='MOHEGY' {
  $primary-color: #0A305E ; // #499ED7;
  $secondary-color: #ECF5FB;
  $footer-bg-color: #9ED18C;
  $black-font-color: #212121;   // #2271B9;
  $header-bg-color: #0A305E;  //#A2A1A1;

  $color-danger: #EF5B50;
  $color-danger-light: #FAE6E4 ;
  $color-success: #1ACC80;
  $color-success-light: #E8F9F2 ;
  $bg-white: #EFF3F8;
  $line-separator: white; // #D0D1D4;

  $secondary-font-size:11px;
  $font-size-label: 11px;
  $font-size-h1: 16px;
  $font-size-h2: 13px;

  .font-light-small,
  .font-primary-small,
  .font-default-small{
    font-size: $font-size-label !important;
  }
  .form-progress {
    .progress{
      .circle{
        border: 1px solid white !important;
        background: white !important;
      }
      .bar{
        background: white !important;
      }
      &.active{
          .bar{
          background: white !important;
        }
        .circle{
          border: 1px solid white !important;
          background: white !important;
        }
      }
      &.completed{
        .bar{
          background: $primary-color !important;
        }
        .circle{
          border: 1px solid $primary-color !important;
          background: $primary-color !important;
        }
      }

    }

  }

  .font-primary-subtitle{
    font-size: $font-size-h1 !important;
  }
  .modal-header {
    .title{
    font-size: $font-size-h1 !important;
    }
  }

  #patient-select-component{
    .title{
      font-size: $font-size-h1 !important;
      font-weight: 500!important;
      color: $primary-color;
      }
      .image{
        display: none !important;
      }
  }
  #upcoming-appointment .upcoming-filter{
    background-color: white !important;
  }
  #hr-visit-history{
    .card{
      background: white !important;
    }
    .card-head{
      font-size: $font-size-h1 !important;
      font-weight: 500!important;
      color: $primary-color;
    }
    .image{
      display: none !important;
    }
    .title{
      font-size: $font-size-h1 !important;
      font-weight: 500!important;
    }
    .font-light-small,
    .font-primary-small{
      font-size: $font-size-p !important;
    }
    .visit-cards .card{
      background: white !important;
      &.active{
        background-color: $primary-color !important;
      }
    }
  }
  #navigation-component {
    .nav-title{
      font-size: $font-size-label !important;
    }
  }

  #list-item ::ng-deep{
    .sub-title{
      font-size: $font-size-p !important;
    }
    .primary-content{
      font-size: $font-size-h2 !important;
      color: $ternary-font-color !important;
      *{
        font-size: $font-size-p !important;
      }
    }
    .footer-section .primary-content {
      *{
      color: $ternary-font-color !important;
      }
    }
    .secondary-content {
      font-size: $font-size-p !important;
    }
    .list-action {
      font-size: $font-size-p !important;
      color: $ternary-font-color;
    }
    .header-section .sub-title{
      font-size: $font-size-h1 !important;
      color: $primary-color !important;
    }
    .header-section .title{
      font-size: $font-size-h1 !important;
      color: $black-font-color !important;
    }
  }
  #header-component {
    .header-card {
      .font-secondary-title {
        font-size: $font-size-p !important;
      }

      .font-primary-title {
        font-size: $secondary-font-size !important;
        color: white !important;
      }
    }
  }
  #search-speciality {
    .card-layout{
      .card-main .card-content{
        width: 100% !important;
        .font-light-small{
          font-size: $font-size-label !important;
        }
      }
      .image{
        display: none !important;
      }
      .container{
        .title{
          font-size: $font-size-p  !important;
        }
      }
      .btn_container{
        .font-light-small{
          font-size: $font-size-p !important;
        }
      }
    }
    .card-footer{
      .days .day .text{
        font-size: $font-size-label !important;
        &.available {
          background:  $color-success-light !important;
          color: $color-success !important;
        }
      }
    }
  }
  #hr-visit-details {
    .nav-tabs-custom{
      font-size: $font-size-h1 !important;
    }
  }
  app-custom-modal-container{
    .header-modal .title{
      font-size: $font-size-h1 !important;
    }
  }
}
@if $theme-name=='ROYALHAYAT' {
  $primary-color: #691637;
  $secondary-color: #FCDBE8;
  $footer-bg-color: #74613C;
  $black-font-color: #424653;
  $header-bg-color: white;
  $calendar-theme : 'ROYALHAYAT';
}
@if $theme-name=='IQVIA' {
  $primary-color: #00AAE7;
  $secondary-color: #C0D8F5;
  $footer-bg-color: #004A7C;
  $black-font-color: #424653;
  $header-bg-color: #C0D8F5;
}
@if $theme-name=='HUMBER' {
  $primary-color: #2b74c7;
  $secondary-color: white;
  $footer-bg-color: #00bfc7;
  $header-bg-color: #A2A1A1;
  $black-font-color: #424653;
}
@if $theme-name=='ARTEMIS' {
  $primary-color: #897D2C;
  $secondary-color: #FCFAED;
  $footer-bg-color: #00bfc7;
  $header-bg-color: #A2A1A1;
  $black-font-color: #424653;
}
@if $theme-name=='MEDANTA' {
  $primary-color: #db5134;
  $secondary-color: white;
  $footer-bg-color: #00bfc7;
  $header-bg-color: #A2A1A1;
  $black-font-color: #424653;
}
@if $theme-name=='CARAT' {
  $primary-color: #831297;
  $secondary-color: #ffffff;
  $footer-bg-color: #00bfc7;
  $header-bg-color: #A2A1A1;
  $black-font-color: #424653;
}
@mixin font-gen($color, $size, $weight:400) {
  color:$color;
  font-size:$size;
  font-weight:$weight;
}
@mixin rtl-value($property, $ltr-value, $rtl-value, $imp:null) {
  #{$property}: $ltr-value $imp;

  :host-context([dir=rtl]) & {
    #{$property}: $rtl-value $imp;
  }
}


@mixin border-radius-gen($tl, $tr, $br, $bl, $imp:null) {
  @include rtl-value(border-top-left-radius, $tl, $tr, $imp);
  @include rtl-value(border-top-right-radius, $tr, $tl, $imp);
  @include rtl-value(border-bottom-right-radius, $br, $bl, $imp);
  @include rtl-value(border-bottom-left-radius, $bl, $br, $imp);
}

@mixin margin-gen($top:null, $right:unset, $bottom:null, $left:unset, $imp:null) {
  margin-top: $top $imp;
  @include rtl-value(margin-right, $right, $left, $imp);
  margin-bottom: $bottom $imp;
  @include rtl-value(margin-left, $left, $right, $imp);
}

@mixin padding-gen($top:null, $right:null, $bottom:null, $left:null, $imp:null) {
  padding-top: $top $imp;
  @include rtl-value(padding-right, $right, $left, $imp);
  padding-bottom: $bottom $imp;
  @include rtl-value(padding-left, $left, $right, $imp);
}

@mixin border-gen($top:null, $right:null, $bottom:null, $left:null, $imp:null) {
  border-top: $top $imp;
  @include rtl-value(border-right, $right, $left, $imp);
  border-bottom: $bottom $imp;
  @include rtl-value(border-left, $left, $right, $imp);
}

@mixin position-gen($top:null, $right:unset, $bottom:null, $left:unset, $imp:null) {
  top: $top $imp;
  @include rtl-value(right, $right, $left, $imp);
  bottom: $bottom $imp;
  @include rtl-value(left, $left, $right, $imp);
}

@if $theme-name !='MOHEGY' {
  #header-component {
    .header-card {
      .justify-center {
        .font-secondary-title {
          font-size: $font-size-p !important;
        }

        .font-primary-title {
          font-size: $font-size-p !important;
        }
      }
    }
  }
}

.color-notify {
  color: $color-notify !important;
}

@mixin responsive($selector, $sm: true, $md: true, $lg: true) {
  .#{$selector} {

      @content;
  }

  @if $sm {
      @media (max-width: 767px) {
          .sm-#{$selector} {
              @content;
          }
      }
  }

  @if $md {
      @media (min-width: 768px) {
          .md-#{$selector} {
              @content;
          }
      }
  }

  @if $lg {
      @media (min-width: 1024px) {
          .lg-#{$selector} {
              @content;
          }
      }
  }

}

.notify-button {
  @include margin-gen($left: .5rem);
  border: 1px solid $color-notify;
  border-radius: 5px;
  color: $bg-white;
  font-size: $font-size-p;
  font-weight: 400;
  background-color: $color-notify;
  line-height: 0.5rem;
  &:disabled,
  &[disabled] {
    opacity: 0.4;
  }
}

.notify-button-inverted {
  @include margin-gen($left: .5rem);
  border: 1px solid $color-notify;
  border-radius: 5px;
  color: $color-notify;
  font-size: $font-size-p;
  font-weight: 400;
  background-color: $bg-white;
  line-height: 0.5rem;
  &:disabled,
  &[disabled] {
    opacity: 0.4;
  }
}


@mixin rotate-img-rtl() {
  :host-context([dir=rtl]) & {
    transform: rotate(180deg);
  }
}

@mixin float-gen($value) {
  $rtl-value: if($value==right, left, right);
  float: $value;

  :dir(rtl) & {
    float: $rtl-value;
  }

  :host-context([dir=rtl]) & {
    float: $rtl-value;
  }

  [dir=rtl] & {
    float: $rtl-value;
  }
}