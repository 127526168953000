/* You can add global styles to this file, and also import other style files */
// @import "~bootstrap/scss/bootstrap";
// @import "~ng-pick-datetime/assets/style/picker.min.css";
:root{
  --base-url: '/';
  --adjust-app-top: 0em;
}
@import "~@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";
@import '~bootstrap/dist/css/bootstrap.min.css';
@import "./variable";
@import "env-variable.scss";
@import "style-guide.scss";
$app-header-height: 4em;

@if $theme-name=='ALNAHDI' {
  @font-face {
    font-family: Roboto;
    src: url('./assets/fonts/Roboto/Roboto-Bold.ttf') format("opentype");
    font-weight: bold;
  }
  @font-face {
    font-family: Roboto;
    src: url('./assets/fonts/Roboto/Roboto-Black.ttf') format("opentype");
    font-weight: bolder;
  }

  @font-face {
    font-family: Roboto;
    src: url('./assets/fonts/Roboto/Roboto-Italic.ttf') format("opentype");
    font-style: italic;
  }

  @font-face {
    font-family: Roboto;
    src: url('./assets/fonts/Roboto/Roboto-Light.ttf') format("opentype");
  }

  @font-face {
    font-family: TheSansArabic-Light;
    src: url('./assets/fonts/TheSansArabic/ArbFONTS-TheSansArabic-Light.otf') format("opentype");
  }

  @font-face {
    font-family: TheSansArabic-Bold;
    src: url('./assets/fonts/TheSansArabic/THESANSARABIC-BOLD.ttf') format("opentype");
  }

  @font-face {
    font-family: Cairo-Black;
    src: url('./assets/fonts/Cairo/Cairo-Black.ttf') format("opentype");
  }

  @font-face {
    font-family: Cairo-Bold;
    src: url('./assets/fonts/Cairo/Cairo-Bold.ttf') format("opentype");
  }

  @font-face {
    font-family: Cairo-Regular;
    src: url('./assets/fonts/Cairo/Cairo-Regular.ttf') format("opentype");
  }

  @font-face {
    font-family: Rubik;
    src: url('./assets/fonts/Rubik/Rubik-VariableFont_wght.ttf') format("opentype");
  }

  @font-face {
    font-family: Rubik;
    src: url('./assets/fonts/Rubik/Rubik-VariableFont_wght.ttf') format("opentype");
    font-weight: 500;
  }

  @font-face {
    font-family: Rubik-LightItalic;
    src: url('./assets/fonts/Rubik/Rubik-LightItalic.ttf') format("opentype");
  }

  @font-face {
    font-family: Rubik-Numeric-BoldItalic;
    src: url('./assets/fonts/Rubik/Rubik-SemiBoldItalic.ttf') format("opentype");
    unicode-range: U+30-39 ;
  }

  @font-face {
    font-family: Rubik-Numeric-LightItalic;
    src: url('./assets/fonts/Rubik/Rubik-LightItalic.ttf') format("opentype");
    unicode-range: U+30-39 ;
  }

} 
@else{
  //font-faces
  @font-face {
    font-family: Poppins;
    src: url('./assets/fonts/poppins/Poppins-Regular.ttf') format("opentype");
  }

  @font-face {
    font-family: Poppins-Bold;
    src: url('./assets/fonts/poppins/Poppins-SemiBold.ttf') format("opentype");
  }

  @font-face {
    font-family: Sensibility;
    src: url('./assets/fonts/Sensibility/Sensibility-Regular.ttf') format("opentype");
    transform: translate(0, 2px);
  }

  @font-face {
    font-family: Sensibility-Light;
    src: url('./assets/fonts/Sensibility/Sensibility-Light.ttf') format("opentype");
  }

  @font-face {
    font-family: Sensibility-Bold;
    src: url('./assets/fonts/Sensibility/Sensibility-Bold.ttf') format("opentype");
  }

  @font-face {
    font-family: Sensibility-Bolder;
    src: url('./assets/fonts/Sensibility/Sensibility-Bold.ttf') format("opentype");
  }
}

/*-------------------------------------------------------------
* Scroll Bar 
*-------------------------------------------------------------*/
body{
  color: $black-font-color!important;
  font-size: $font-size-h2!important;
}
*{
  box-sizing: border-box;
}
/* width */
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}


/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $primary-color;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;

}


::-webkit-scrollbar-thumb,
::-webkit-scrollbar-track {
  border-bottom-right-radius: 0;
}

::-webkit-scrollbar-thumb,
::-webkit-scrollbar-track {
  border-top-right-radius: 0;
}

/*-------------------------------------------------------------
* 
*-------------------------------------------------------------*/
.header-component{
  // height: $app-header-height;
  width: 100%;
  &+*{
    height: calc(100% - #{$app-header-height});
    width: 100%;
    // padding: 1em 0em 0em 0em;
    @include padding-gen($top:1em, $right:0em, $bottom:0em, $left:0em);
    overflow: auto;
  }
}

*>app-list-item:last-child>#list-item{
  border-bottom: 0px !important;
}

html,
body {
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.cursor-pointer{
  cursor: pointer;
  *{
    cursor: pointer;
  }
}
.no-padding {
  padding: 0 !important;
}
.fluid {
  height: 100%;
  width: 100%;
}
.no-margin {
  margin: 0 !important;
}
.padding-top {
  padding-top: 0.5em;
}
.padding-bottom {
  padding-bottom: 0.5em;
}
.light {
  font-weight: 300 !important;
  font-weight: bold !important;
}
.normal {
  font-weight: 400;
}

h6 {
  font-size: $primary-font-size;
}
.border-none {
  border: 0px !important;
}
html,
body {
  height: 100%;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
div,
span {
  cursor: inherit;
  // user-select: none;
  // -webkit-user-select: none;
  // -moz-user-select: none;
}
select,
input[type=radio] {
  cursor: pointer;
}
/*------------------------------------------------------------------
*   CSS for Pop over , please dont touch it , still working on this
*-------------------------------------------------------------------*/
app-popover {
  max-height: 324px;
  background: #fff;
  transition: 0.5s ease-in-out;
  transform: scale(0);
  opacity: 0;
  max-width: 28em !important;
  width: auto !important;
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.2);
  overflow: auto;
}
app-popover-form-preview {
  max-height: 23em;
  background: #fff;
  transition: 0.5s ease-in-out;
  transform: scale(0);
  opacity: 0;
  max-width: 21em !important;
  width: auto !important;
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.2);
  background: white;
  z-index: 9999 !important;
  overflow: auto;
}
.display {
  transform: scale(1) !important;
  opacity: 1 !important;
  display: block !important;
}
/* annotation */
.annotation {
  position: relative;
  left: 305px;
  top: 22px;
  color: #ffffff99;
  letter-spacing: 2px;
  animation: move 1s ease-in-out infinite normal forwards running;
}
@keyframes move {
  0% {
    transform: translatex(0);
  }
  100% {
    transform: translatex(8px);
  }
}

/*-------------------------------------------------------------------
*  END
*-------------------------------------------------------------------*/
html,
body {
  height: 100%;
  padding: 0 !important;
  line-height: 20px !important;
}

body {
  margin: 0;
  font-family:  Roboto, Poppins, "Helvetica Neue", sans-serif !important;
  --lumo-font-family:  Roboto, Poppins, "Helvetica Neue", sans-serif;
}
html[dir=rtl] body {
  font-family:  Cairo-Regular, "Helvetica Neue", sans-serif !important;
  --lumo-font-family:  Roboto, Cairo-Regular, "Helvetica Neue", sans-serif !important;
}
.bold {
  font-family: Roboto, Poppins-Bold;
  font-weight: bold !important;
}
.title-bold {
  font-family: Roboto, Poppins-Bold;
  font-weight: bold !important;
  font-size: $font-size-p;
}
@if $theme-name=='MOHEGY' {
  .bold {
  font-family: Roboto, Poppins;
  font-weight: bold !important;
  }
}

html[dir=rtl] .bold {
  font-family: Cairo-Bold !important;
  font-weight: bold !important;
}

.bolder {
  font-family: Roboto, Poppins;
  font-weight: bolder !important;
}

html[dir=rtl] img:not(.type-image):not(.type-image-light) {
  transform: rotateY(180deg);
}

html img.arrow-img {
  transform: rotate(-90deg) !important;
}
html[dir=rtl] img.arrow-img {
  transform: rotate(90deg) !important;
}

//css for owl date time picker in due date extension
.owl-dt-calendar-table .owl-dt-calendar-cell-selected {
  background-color: $primary-color;
}
.owl-dt-container-control-button .owl-dt-control-button-content {
  color: $primary-color;
}
.owl-dt-calendar-table .owl-dt-calendar-cell-selected {
  background-color: $primary-color;
}
//css to show no data component center in lead info
.no-data-display {
  display: flex;
  align-items: center;
  justify-content: center;
}
.vertical-align {
  display: flex;
  align-items: center;
}
.pointer {
  cursor: pointer !important;
}
.btn-group .inactive {
  background: white;
  color: $primary-color;
}
.non-hover:hover {
  background: none !important;
}

.justify-center {
  justify-content: center;
}

.error {
  color: red;
}

.black {
  color: black !important;
}

.validators {
  font-size: $font-size-h1;
  padding-left: 8px;
  padding-top: 8px;
  margin: 0;
}
.validators::before {
  content: "*";
}
.margin-right {
  @include margin-gen($right: 16px );
}
.margin-left {
  @include margin-gen($left: 16px )
}
.margin-top {
  @include margin-gen($top: 16px );
}
.margin-bottom {
  @include margin-gen($bottom: 16px )
}
.margin {
  margin: 16px;
}
.flex {
  display: flex;
}
.inprogress-wf {
  padding-top: 10px;
  padding-bottom: 10px;
}
.truncate {
  width: 100% !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.padding-left {
  padding-left: 1em;
}
.padding {
  padding: 1em;
}
.report-icon {
  height: 4em;
  display: flex;
  justify-content: center;
  align-items: center;
}
.event-desc {
  font-size: $primary-font-size;
  padding: 0;
  line-height: 1.2;
}
.event-detail {
  line-height: 1.5;
  font-size: $primary-font-size;
}
app-prompt {
  height: 100%;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.3);
  bottom: 0;
  right: 0;
}
.search-input {
  display: inline-flex;
  width: 100%;
  height: 3em;
  border-bottom: 1px solid $border-color;
  background: $primary-color;
  img {
    // margin: 0 10px;
    @include margin-gen($top:0, $right:10px, $bottom:0, $left:10px);
  }
  :focus {
    outline: none;
    color: black;
  }
  input {
    outline: none;
    border: none;
    font-size: $secondary-font-size;
    width: 80%;
    height: 100%;
    padding-left: 3%;
  }
  .search-button {
    width: 20%;
    height: 100%;
    align-items: center;
    text-align: center;
    justify-content: center;
    display: flex;
    img {
      height: 50%;
    }
  }
  .search-button:hover {
    cursor: pointer;
  }
}
.border-right {
  // border-right: 1px solid $border-color;
  border-inline-end: 1px solid $border-color;
}
.border-left {
  border-inline-start: 1px solid $border-color;
}
.border-bottom {
  border-bottom: 1px solid $border-color;
}
.border-top {
  border-top: 1px solid $border-color;
}
.border {
  border: 1px solid $border-color;
}
.uppercase {
  text-transform: uppercase;
}
.display-inline {
  display: inline;
}
.captilize{
  text-transform: capitalize;
}
button {
  @include padding-gen($top: .75em, $right:1.5em, $bottom: .75em, $left:1.5em, $imp: !important);
  border-radius: 5px !important;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none !important;
  vertical-align: middle;
  &.btn-small{
    @include padding-gen($top: .5em, $right:1.25em, $bottom: .5em, $left:1.25em, $imp: !important);
  }
  &.btn-flat{
    box-shadow: none!important;
    outline:none;
  }
  &.btn-round{
    border-radius: 50%!important;
  }
  &.btn-icon{
    box-shadow: none !important;
    outline:none;
    border-radius: 50% !important;
    width: 3em;
    height: 3em;
    display: flex;
    align-items: center;
    justify-content: center;
    padding:0px!important;
    img{
      margin: 0px!important;
    }
  }
  &.disabled{
    opacity: 0.5;
  }
}
@function set-text-color($color, $light-alternative, $dark-alternative) {
  @if (lightness($color) > 40) {
    @return $light-alternative;
  }

  @else {
    @return $dark-alternative;
  }
}
//Font Styles
$colors:(
  "default": $black-font-color,
  "primary": $primary-color,
  "primary1": $primary-color,
  "secondary": $secondary-color,
  "light": $ternary-font-color,
  "white": #FFFFFF,
  "warning": $color-warning,
  "warninglight": $color-warning-light,
  "danger": $color-danger,
  "dangerlight": $color-danger-light,
  "success": $color-success,
  "successlight": $color-success-light,
  "info": $color-info,
  "infolight": $color-info-light,
  "label": $label-highlight-color,
  "headerprimary": set-text-color($header-bg-color, $primary-color, white ),
  "headersecondary": set-text-color($header-bg-color, $black-font-color, white)
);
$fontsizes:(
  title: $font-size-h1,
  subtitle: $font-size-h2,
  default: inherit,
  small: $font-size-p,
  small1: $font-size-p,
  xssmall: $font-size-cap
);
@mixin button-style-generator{
  @each $colorname,$colorval in $colors {
    .#{$colorname}-button{
      background-color: $colorval;
      color: #fff;
      font-size: $font-size-p;
      box-shadow: $box-shadow-bottom-dark;
      border: 0;
      height: 1.8rem;
      &:disabled,
      &[disabled] {
        opacity: 0.4;
      }
      img{
        width: 15px;
        @include margin-gen($right: 5px );
      }
      &:not([read-only]):hover{
        color: #fff;
      }
      &-outline{
        border: 1px solid $colorval;
        background-color: #fff;
        color: $colorval;
        &:hover {
          color: $colorval;
        }
      }
      &.btn-flat{
        box-shadow: none;
      }
      &.btn-small{
        padding: 5px 1em !important;
      }
    }
    @each $fontname, $fontval in $colors {
      .#{$colorname}-font-#{$fontname}-button {
        background-color:$colorval;
        color: $fontval;
        font-size: $font-size-p;
        border: 0;
        &:disabled,
        &[disabled] {
          opacity: 0.4;
        }

        img {
          width: 15px;
          @include margin-gen($right: 5px );
        }

        &:hover {
          color: $fontval;
        }
      }
    }
  }
}
@mixin font-style-generator{
  @each $colorname,$colorval in $colors {
    @each $fontname, $fontval in $fontsizes {
      .font-#{$colorname}-#{$fontname}{
        color:$colorval;
        font-size:$fontval;
      }
    }
  }
}
@include button-style-generator;
@include font-style-generator;
.row{
  margin: 0px !important;
  flex-wrap: nowrap !important;
  --bs-gutter-x: unset !important;
  --bs-gutter-y: unset !important;
}
vaadin-date-picker-overlay{
  z-index: 2000;
}
.no-data{
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 100%;
  padding: 2em;
}
.title-case{
  text-transform: capitalize;
}

body{
  text-align: start!important;
}

// html{
//   direction: ltr;
// }
vaadin-date-picker, vaadin-text-field{
  margin: 0px !important;
  padding: 0px !important;
}

//adjustment
.font-light-small,
.font-primary-small,
.font-default-small{
  font-size: initial;
}

html[dir=rtl] #patient-select-component .scroller.scroll-right img {
  transform: rotate(180deg) rotateY(180deg) !important;
}


//animation
.showModal{
  opacity: 1;
  z-index: 30;
  animation: show .25s;
  transform: scale(1);
}

@keyframes show {
  from {
      // transform: scale(0);
      transform: translateX(500px);
      opacity: 0;
      z-index: -1;
  }
  to {
      // transform: scale(1);
      transform: translateX(0px);
      opacity: 1;
      z-index: 30;
  }
}

.word-brk{
  word-break: break-word;
}  

.dttm-override{
  unicode-bidi: plaintext;
}
html[dir=rtl] .ans-txt {
  unicode-bidi: plaintext;
  text-align: right;
}

.input-group-append{
  display: none !important;
}

// @import "~@mobiscroll/angular/dist/css/mobiscroll.scss";

.ngb-dp-arrow{
  justify-content: center !important;
  align-items: center !important;
  display: flex !important;
}
.asclass {
  img {
    width: 2em;
    height: 2em;
    @include margin-gen($right: 0.5em)
  }
}

.abclass {
  img {
    width: 15em;
    height: 15em;
  }
}
.delayRunning {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
.three-quarter-spinner {
  width: 50px;
  height: 50px;
  border: 3px solid $primary-color;
  border-top: 3px solid transparent;
  border-radius: 50%;
  animation: spin .5s linear 0s infinite;
}
@keyframes spin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(359deg);
  }
}
.common-welcome-text {
  p {
    margin:0;
  }
  .welcome-title {
    @include font-gen($black-font-color,$primary-font-size, 500);
  }
  .welcome-subtitle {
    @include font-gen($black-font-color, $secondary-font-size);

  }
}